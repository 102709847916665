BABYLON = require 'babylonjs'
GUI = require 'babylonjs-gui'

window.campushero = {
    assets: {
        loadChecker: undefined,
        loadCheckerInterval: 200,
        manager: undefined,
        meshTasks: [],
        openTasks: 0,
        rootUrl: "3d-assets/"
    },
    camera: undefined,
    cameraPinchPrecision: 30,
    cameraRadius: {
        near: 6,
        far: 50
    },
    cameraStartPosition: new BABYLON.Vector3(-10, 9, -15),
    cameraTargetNulled: true,
    canvas: undefined,
    config: {
        debug: false,
        fxaa: false,
        shadows: true
    },
    dpr: if devicePixelRatio then devicePixelRatio else 1,
    engine: undefined,
    gui: undefined,
    guiRenderScale: 1,
    hsl: undefined,
    infopointClick: false,
    infopoints: {
        cameraZoomShiftLevel: 20,
        closeByCanvasClick: true,
        data: [
            {
                name: "human",
                overlay: {
                    buttonTarget: "#overlay-mensch",
                    buttonText: "Mehr erfahren",
                    text: "Wie wir arbeiten, wo wir arbeiten und mit wem – all das verändert sich. Was sich nicht verändert: Bei allem steht der Mensch im Mittelpunkt.",
                }
                offPosition: new BABYLON.Vector3(11, .5, -4),
                position: new BABYLON.Vector3(8, 3, -3),
                text: "Mensch",
                textAlignment: 1,
                textOffset: 10
            },
            {
                name: "room",
                overlay: {
                    buttonTarget: "#overlay-raum",
                    buttonText: "Mehr erfahren",
                    text: "New Work bekommt ein Gesicht. Unsere neuen Räume sind Sinnbild unseres Zusammenarbeitsmodells und zeigen: Das Büro ist dort wo ihr seid.",
                },
                offPosition: new BABYLON.Vector3(-7.6, .5, -5.5),
                position: new BABYLON.Vector3(-3, 2, -4),
                text: "Raum",
                textAlignment: -1
            },
            {
                name: "tech",
                overlay: {
                    buttonTarget: "#overlay-technologie",
                    buttonText: "Mehr erfahren",
                    text: "Remote, hybrid oder face-to-face – Neues Arbeiten braucht neue Technologien. Sie helfen uns bestmöglich zu kommunizieren und standortübergreifend zu vernetzen.",
                },
                offPosition: new BABYLON.Vector3(10, 6, 10),
                position: new BABYLON.Vector3(2.5, 2.5, 5.5),
                text: "Technologie",
                textAlignment: 1,
                textOffset: 40
            },
            {
                name: "life",
                overlay: {
                    buttonTarget: "#overlay-leben",
                    buttonText: "Mehr erfahren",
                    text: "Arbeit ist das halbe Leben, sagt der Volksmund. Wir sagen: Arbeit und Leben gehören zusammen. Wie ihr euch maximal entfalten und entwickeln könnt, erfahrt ihr hier.",
                },
                offPosition: new BABYLON.Vector3(-10, 5, 3),
                position: new BABYLON.Vector3(-6, 2, 1),
                text: "Leben",
                textAlignment: -1
            }
        ],
        elements: [],
        overlayDefaultOffset: 200,
        sprite: {
            file: "elem_plus-symbol.png",
            height: 600,
            manager: undefined,
            size: {
                default: .75,
                hover: .85
            },
            width: 600
        },
        textElements: [],
        textElementsDefaultOffset: 110,
        trackSpheres: [],
        trackSpheresMaterial: undefined
    },
    lights: [],
    meshes: [],
    overlayOpen: false,
    postProcesses: [],
    resizeFactor: 1,
    scene: undefined,
    sceneOptimized: false,
    sceneOptimizer: undefined,
    shadowGens: [],
    targetedFps: 45,
    touchDetected: false,
    userHintsAnimation: undefined,
    userHintsAnimationTransitionTime: 5000,
    waypoints: {
        cameraLift: 3,
        data: [
            {
                name: "human",
                position: new BABYLON.Vector3(5.2, .1, -2),
                target: new BABYLON.Vector3(8, 3, -3)
            },
            {
                name: "room",
                position: new BABYLON.Vector3(-3, .66, -5.8),
                target: new BABYLON.Vector3(-3, 2, -4)
            },
            {
                name: "tech",
                position: new BABYLON.Vector3(6.5, .66, 3.3),
                target: new BABYLON.Vector3(2.5, 2.5, 5.5)
            },
            {
                name: "life",
                position: new BABYLON.Vector3(-4, .31, .5),
                target: new BABYLON.Vector3(-6, 2, 1)
            }
        ],
        elements: [],
        material: undefined,
        texture: {
            animated: true,
            animation: undefined,
            animationStep: .05,
            animationTime: 100,
            file: "tex_waypoint.png",
            texture: undefined,
        },
        transitionTime: 1000,
        triggerDelay: undefined
    },
    windowResizeDelay: 100,
    windowResizeInterval: undefined
}
app = window.campushero

app.CreateScene = ->
    app.canvas = document.querySelector 'canvas.campushero__canvas'
    app.canvas.width = window.innerWidth
    app.canvas.height = window.innerHeight

    app.engine = new BABYLON.Engine app.canvas, true
    app.engine.setHardwareScalingLevel (1 / window.devicePixelRatio)

    app.hsl = app.engine.getHardwareScalingLevel()

    app.scene = new BABYLON.Scene app.engine
    app.scene.clearColor = new BABYLON.Color4 0, 0, 0, 0 # transparent background
    if app.config.debug
        app.scene.debugLayer.show()
    app.scene.shadowsEnabled = app.config.shadows

    app.engine.displayLoadingUI()

    # ++++ Camera ++++
    app.camera = new BABYLON.ArcRotateCamera "camera", -Math.PI / 2, Math.PI / 2.5, 3, app.cameraStartPosition, app.scene
    app.camera.attachControl app.canvas, true
    # disable free movement
    app.camera.inputs.removeByType "ArcRotateCameraKeyboardMoveInput"
    app.camera.inputs.removeByType "ArcRotateCameraMouseMoveInput"
    # limit zoom
    app.camera.upperRadiusLimit = app.cameraRadius.far
    app.camera.lowerRadiusLimit = app.cameraRadius.near
    # limit rotation
    app.camera.lowerBetaLimit = 0
    app.camera.upperBetaLimit = Math.PI * (70 / 180)
    # set pinch precision for mobile zooming
    app.camera.pinchPrecision = app.cameraPinchPrecision
    
    app.camera.useFramingBehaviour = true
    app.camera.setTarget BABYLON.Vector3.Zero()

    # ++++ GUI ++++
     # create UI if not exists
    if !app.gui
        app.gui = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI", undefined, undefined, BABYLON.Texture.LINEAR_LINEAR)
        app.gui.renderScale = app.guiRenderScale

    # ++++ Lights ++++
    dLight = app.AddLight "direct", "#dLight-01", [12, -10, 18], [1, -1, 1]
    dLight.intensity = 3
    dLightShadowGen = app.CreateShadowGenerator dLight, 1024, 10, 100, true, false

    dLight2 = app.AddLight "direct", "#dLight-02", [0, -1, 0], [-1, -1, -1]
    dLight2.intensity = 1

    sLight = app.AddLight "spot", "#sLight-01", [-8.32, 2.93, 1.06], [.64, -.23, .73], 10, 24
    sLight.intensity = 50
    sLight.diffuse = new BABYLON.Color3 1, .9, .8

    # +++ Assets Manager ++++
    app.assets.manager = new BABYLON.AssetsManager app.scene
    # disable default loading screen
    app.assets.manager.useDefaultLoadingScreen = false
    # load mesh assets
    app.AddMeshTask "campusmodel", "campushero_220315.babylon", dLightShadowGen
    app.AddMeshTask "campusmodel_highchair", "campushero_220315_HighChair.babylon", dLightShadowGen
    app.AddMeshTask "campusmodel_kitchenseat", "campushero_220315_KitchenSeat.babylon", dLightShadowGen
    app.AddMeshTask "campusmodel_deskgroup", "campushero_220315_DeskGroup.babylon", dLightShadowGen
    app.AddMeshTask "campusmodel_kitchentable", "campushero_220315_KitchenTable.babylon", dLightShadowGen
    app.LoadAssets()

    # ++++ Infopoints ++++
    app.CreateInfopoints()

    # ++++ Post Processes ++++
    if app.config.fxaa
        app.AddPostProcess "fxaa", new BABYLON.FxaaPostProcess("fxaa", 1.0, app.camera)

    # ++++ Waypoints ++++
    app.CreateWaypoints()

    # ++++ Scene Optimizer ++++
    app.CreateSceneOptimizer()

    return app.scene

# #### ASSETS ####
app.AddMeshTask = (name, fileName, shadowGen) ->
    # create new mesh task
    app.assets.meshTasks[name] = app.assets.manager.addMeshTask name, "", app.assets.rootUrl, fileName
    # increase openTasks count
    app.assets.openTasks++
    # success function
    app.assets.meshTasks[name].onSuccess = (task) ->
        #task.loadedMeshes[0].position = BABYLON.Vector3.Zero
        for mesh in task.loadedMeshes
            if shadowGen
                shadowGen.addShadowCaster mesh                
                mesh.receiveShadows = true
            app.meshes[mesh.id] = mesh
        app.assets.openTasks--
        return
    # error function
    app.assets.meshTasks[name].onError = (task, message, exception) ->
        console.log "MeshTask loading error: ", message, exception
        app.assets.openTasks--
        return
    return

app.LoadAssets = ->
    # show loading screen
    app.engine.displayLoadingUI()
    # start assets manager loading
    app.assets.manager.load()
    # start load checker
    app.StartAssetsLoadChecker()
    return

app.StartAssetsLoadChecker = ->
    if !app.assets.loadChecker
        app.assets.loadChecker = window.setInterval ->
            if app.config.debug
                console.log "CHECK FOR LOAD"
            if app.assets.openTasks < 1
                window.clearInterval app.assets.loadChecker
                app.assets.loadChecker = undefined
                app.engine.hideLoadingUI()

                # Instances
                app.InstantiateMesh app.meshes["HighChair"], 1, [0, 0, 1.2], app.shadowGens["#dLight-01"]
                app.InstantiateMesh app.meshes["HighChair"], 2, [0, 0, -1.2], app.shadowGens["#dLight-01"]

                kitchenSeat = app.InstantiateMesh app.meshes["KitchenSeat"], 1, [.5, 0, -12.6], app.shadowGens["#dLight-01"]
                kitchenSeat.rotation.z = Math.PI * (270 / 180)

                app.InstantiateMesh app.meshes["DeskGroup"], 1, [-3, 0, 0], app.shadowGens["#dLight-01"]
                deskGroup2 = app.InstantiateMesh app.meshes["DeskGroup"], 2, [4.8, 0, 11.1], app.shadowGens["#dLight-01"]
                deskGroup2.rotation.z = Math.PI
                deskGroup3 = app.InstantiateMesh app.meshes["DeskGroup"], 3, [7.8, 0, 11.1], app.shadowGens["#dLight-01"]
                deskGroup3.rotation.z = Math.PI

                app.InstantiateMesh app.meshes["KitchenTable"], 1, [-11, .27, 10.7], app.shadowGens["#dLight-01"]

            return
        , app.assets.loadCheckerInterval
    return

app.InstantiateMesh = (mesh, instanceNumber, positionShift, shadowGen) ->
    app.meshes[mesh.id + "_i_" + instanceNumber] = mesh.createInstance mesh.id + "_i_" + instanceNumber
    app.meshes[mesh.id + "_i_" + instanceNumber].position = new BABYLON.Vector3 mesh.position.x + positionShift[0], mesh.position.y + positionShift[1], mesh.position.y + positionShift[2]
    app.meshes[mesh.id + "_i_" + instanceNumber].isPickable = false
    if shadowGen
        shadowGen.addShadowCaster app.meshes[mesh.id + "_i_" + instanceNumber]
    return app.meshes[mesh.id + "_i_" + instanceNumber]

# #### INFOPOINTS ####
app.CreateInfopoints = ->
    # load global sprite texture
    app.infopoints.sprite.manager = new BABYLON.SpriteManager "infopointSpriteManager", (app.assets.rootUrl + app.infopoints.sprite.file), app.infopoints.sprite.width, app.infopoints.sprite.height, app.scene
    # set sprite manager on higher render group to always render above other 3D content
    app.infopoints.sprite.manager.renderingGroupId = 1
    # make sprite manager pickable for actions
    app.infopoints.sprite.manager.isPickable = true

    # create global track spheres material
    app.infopoints.trackSpheresMaterial = new BABYLON.StandardMaterial "trackspheres", app.scene
    app.infopoints.trackSpheresMaterial.alpha = 0

    # build individual infopoints
    count = 0
    for infopointData in app.infopoints.data
        # create sprite from global sprite texture
        infopoint = new BABYLON.Sprite ("infopoint_" + infopointData.name), app.infopoints.sprite.manager
        # set position and size
        infopoint.position = infopointData.position
        infopoint.size = app.infopoints.sprite.size.default
        infopoint.isVisible = false

        # create track sphere as mesh for overlay positioning
        trackSphere = BABYLON.MeshBuilder.CreateSphere("infopoint_track-sphere_" + infopointData.name, {}, app.scene)
        trackSphere.position = infopointData.position
        trackSphere.isPickable = false
        trackSphere.material = app.infopoints.trackSpheresMaterial
        app.infopoints.trackSpheres["infopoint_" + infopointData.name] = trackSphere

        # create text elements for infopoints#
        textElement = new GUI.Button.CreateSimpleButton(infopointData.name, infopointData.text)
        textElement.background = 'rgba(0,0,0,0)'
        textElement.color = "#085AE2"
        textElement.textWrapping = true
        textElement.resizeToFit = true
        textElement.height = (50 * app.guiRenderScale * app.dpr) + "px"
        textElement.fontFamily = "ATRUVIA,Helvetica,Arial,sans-serif"
        textElement.fontWeight = 500
        textElement.fontSize = 36 * app.guiRenderScale * app.dpr
        textElement.thickness = 0
        textElement.pickable = true
        ctx = app.gui.getContext()
        ctx.font = textElement.fontSizeInPixels + "px " + textElement.fontFamily
        textMetrics = ctx.measureText(textElement.text)
        textElement.width = (textMetrics.width + (40 * app.guiRenderScale  * app.dpr)) + "px"
        
        app.gui.addControl textElement
        textElement.linkWithMesh app.infopoints.trackSpheres["infopoint_" + infopointData.name]
        textOffset = if infopointData.textOffset then infopointData.textOffset else 0
        textElement.linkOffsetX = ((app.infopoints.textElementsDefaultOffset + textOffset) - (1 * app.camera.radius)) * infopointData.textAlignment * app.guiRenderScale * app.dpr
        textElement.isVisible = false


        textElement.onPointerUpObservable.add (e, eventState) ->
            app.infopointClick = true
            app.ShowInfopointOverlay app.infopoints.data.indexOf(app.infopoints.data.find((element) -> element.name == eventState.target.name))
            return
        
        app.infopoints.textElements["infopoint_" + infopointData.name] = textElement

        # make pickable for actions
        infopoint.isPickable = true
        infopoint.actionManager = new BABYLON.ActionManager app.scene

        # bind click action   
        infopoint.actionManager.registerAction new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickUpTrigger, (e) ->
            app.infopointClick = true
            app.ShowInfopointOverlay app.infopoints.data.indexOf(app.infopoints.data.find((element) -> element.name == e.source.name.substring(10,e.source.name.length)))
            return
        ,null)
        # bind hover actions
        infopoint.actionManager.registerAction new BABYLON.InterpolateValueAction(BABYLON.ActionManager.OnPointerOutTrigger, infopoint, "size", app.infopoints.sprite.size.default, 150)
        infopoint.actionManager.registerAction new BABYLON.InterpolateValueAction(BABYLON.ActionManager.OnPointerOverTrigger, infopoint, "size", app.infopoints.sprite.size.hover, 150)

        # add to global infopoints elements list
        app.infopoints.elements["infopoint_" + infopointData.name] = infopoint

        count++
    
    # close infopoint overlays by canvas click if options is set
    if app.infopoints.closeByCanvasClick
        app.canvas.addEventListener 'click', (e) ->
            if !app.infopointClick
                app.RemoveInfopointOverlay()
            else
                app.infopointClick = false
            return
        app.canvas.addEventListener 'touchend', (e) ->
            if !app.infopointClick
                app.RemoveInfopointOverlay()
            else
                app.infopointClick = false
            return

    return

app.ShowInfopointOverlay = (number) ->
    if app.config.debug
        console.log "Show infopoint overlay ", number
    app.RemoveInfopointOverlay()
    infopointData = app.infopoints.data[number]
    if infopointData
        ipoElem = document.createElement 'div'
        ipoElem.classList.add 'campushero__infopoint-overlay'
        ipoElem.innerHTML = '<div class="campushero__infopoint-overlay__header"><h2>' + infopointData.text + '</h2><div class="campushero__infopoint-overlay__text">' + infopointData.overlay.text + '</div><a class="campushero__infopoint-overlay__button">' + infopointData.overlay.buttonText + '</a></div>'
        ipoElem.querySelector('.campushero__infopoint-overlay__button').addEventListener 'click', (e) ->
            if app.config.debug
                console.log "BUTTON CLICK", infopointData.overlay.buttonTarget
            menuElem = document.querySelector('.header__menu-link[href="' + infopointData.overlay.buttonTarget + '"]')
            if menuElem
                window.setTimeout ->
                    app.RemoveInfopointOverlay()
                    menuElem.click()
                , 20
            return
        document.querySelector('body').appendChild ipoElem
    return
app.RemoveInfopointOverlay = ->
    ipoElem = document.querySelector '.campushero__infopoint-overlay'
    if ipoElem
        ipoElem.remove()
        app.overlayOpen = false
    return

    return
app.InfopointsZoomShifter = ->
    # shift infopoints overlay linkOffset with camera zoom
    offset = app.infopoints.overlayDefaultOffset - (1 * app.camera.radius) * app.guiRenderScale * app.dpr
    count = 0
    for name, textElement of app.infopoints.textElements
       textElement.linkOffsetX = offset
       textOffset = if app.infopoints.data[count].textOffset then app.infopoints.data[count].textOffset else 0
       textElement.linkOffsetX = ((app.infopoints.textElementsDefaultOffset + textOffset) - (1 * app.camera.radius)) * app.infopoints.data[count].textAlignment * app.guiRenderScale * app.dpr * app.resizeFactor
       count++

    # shift infopoints and trackSpheres positions
    count = 0
    for name, trackSphere of app.infopoints.trackSpheres
        if app.camera.radius > app.infopoints.cameraZoomShiftLevel
            position = app.infopoints.data[count].offPosition
        else
            position = app.infopoints.data[count].position
        trackSphere.position = position
        app.infopoints.elements[name].position = position
        count++

    # hide textElements of infopoints
    for name, textElement of app.infopoints.textElements
        textElement.isVisible = if app.overlayOpen then false else app.camera.radius > app.infopoints.cameraZoomShiftLevel

    if !app.cameraTargetNulled
        if app.camera.radius > app.infopoints.cameraZoomShiftLevel
            app.cameraTargetNulled = true
            app.camera.setTarget new BABYLON.Vector3(0,0,0)

    return

# #### POST PROCESSES ####
app.AddPostProcess = (name, postProcess) ->
    app.postProcesses[name] = postProcess
    return

# #### LIGHTS ####
app.AddLight = (type, name, position, direction, spotLightConeAngle, spotLightExponent) ->
    if type == "direct"
        app.lights[name] = new BABYLON.DirectionalLight name, new BABYLON.Vector3(position[0], position[1], position[2]), app.scene
        if direction
            app.lights[name].direction = new BABYLON.Vector3 direction[0], direction[1], direction[2]
    if type == "hemi"
        app.lights[name] = new BABYLON.HemisphericLight name, new BABYLON.Vector3(position[0], position[1], position[2]), app.scene
    if type == "point" 
        app.lights[name] = new BABYLON.PointLight name, new BABYLON.Vector3(position[0], position[1], position[2]), app.scene
    if type == "spot" && direction && spotLightConeAngle && spotLightExponent
        app.lights[name] = new BABYLON.SpotLight(name, new BABYLON.Vector3(position[0], position[1], position[2]), new BABYLON.Vector3(direction[0], direction[1], direction[2]), spotLightConeAngle, spotLightExponent, app.scene)
    return app.lights[name]

# #### LOADING SCREEN ####
BABYLON.DefaultLoadingScreen.prototype.displayLoadingUI = ->
    if document.getElementById 'campushero-loading-screen'
        document.getElementById('campushero-loading-screen').style.display = "initial"
        return
    @._loadingDiv = document.createElement 'div'
    @._loadingDiv.id = 'campushero-loading-screen'
    @._loadingDiv.classList.add 'campushero-loading-screen'
    @._loadingDiv.innerHTML = '<div class="campushero-loading-screen__spinner"></div><div class="campushero-loading-screen__text">Daten werden geladen</div>'

    @._resizeLoadingUI()
    window.addEventListener 'resize', @._resizeLoadingUI
    document.body.appendChild @._loadingDiv
    return

BABYLON.DefaultLoadingScreen.prototype.hideLoadingUI = ->
    document.getElementById('campushero-loading-screen').classList.add 'campushero-loading-screen__hidden'
    if app.config.debug
        console.log "SCENE LOADED - LOADING SCREEN HIDDEN"
    return

# #### SCENE OPTIMIZER ####
app.CreateSceneOptimizer = ->
    if !app.sceneOptimizer
        options = new BABYLON.SceneOptimizerOptions app.targetedFps, 500
        options.addOptimization new BABYLON.ShadowsOptimization(0)
        options.addOptimization new BABYLON.TextureOptimization(1, 1024)
        options.addOptimization new BABYLON.HardwareScalingOptimization(2, 1)
    
        app.sceneOptimizer = new BABYLON.SceneOptimizer app.scene, options
        app.sceneOptimizer.onSuccessObservable.add ->
            if app.config.debug
                console.log "OPTIMIZATION WORKED"
            app.PostSceneOptimizationProcesses()
            return
        app.sceneOptimizer.onFailureObservable.add ->
            if app.config.debug
                console.log "OPTIMIZATION FAILED"
            app.PostSceneOptimizationProcesses()
            return
    app.sceneOptimizer.start()
    return
app.PostSceneOptimizationProcesses = ->
    for name, textElement of app.infopoints.textElements
        # set infopoints visible
        app.infopoints.elements[name].isVisible = true

        if app.engine.getHardwareScalingLevel() != app.hsl
            app.resizeFactor = app.engine.getHardwareScalingLevel() / app.dpr
            app.resizeFactor = if app.resizeFactor < .5 then .5 else app.resizeFactor
            # text elements rescale
            textElement.scaleX = app.resizeFactor
            textElement.scaleY = app.resizeFactor
            
        # text element set visible
        textElement.isVisible = true

    if app.engine.getHardwareScalingLevel() != app.hsl       
        app.engine.resize()
    app.sceneOptimized = true
    return

# #### USER HINTS ####
app.UserHintsAnimation = ->
    document.querySelector('.campushero__userhints .icon').classList.add 'active'
    document.querySelector('body').addEventListener 'touchstart', (e) ->
        if !app.touchDetected
            app.touchDetected = true
            for icon in document.querySelectorAll '.campushero__userhints .icon'
                useTag = icon.querySelector('use')
                useTag.setAttribute 'xlink:href', useTag.getAttribute('xlink:href').slice(0,-5) + 'touch'
        return
    if document.querySelector '.campushero__userhints'
        app.userHintsAnimation = window.setInterval ->
            uhElem = document.querySelector '.campushero__userhints'
            nextElem = uhElem.querySelector('.icon.active').nextElementSibling
            uhElem.querySelector('.icon.active').classList.remove 'active'
            if nextElem
                nextElem.classList.add 'active'
            else
                uhElem.querySelectorAll('.icon')[0].classList.add 'active'
            return
        , app.userHintsAnimationTransitionTime
    return


# #### WAYPOINTS ####
app.CreateWaypoints = ->
    # make global material
    app.waypoints.material = new BABYLON.StandardMaterial "waypoint", app.scene
    app.waypoints.texture.texture = new BABYLON.Texture (app.assets.rootUrl + app.waypoints.texture.file), app.scene
    app.waypoints.material.diffuseTexture = app.waypoints.texture.texture
    app.waypoints.material.diffuseTexture.hasAlpha = true
    app.waypoints.material.emissiveTexture = app.waypoints.texture.texture
    app.waypoints.material.opacityTexture = app.waypoints.texture.texture
    app.waypoints.material.alpha = .5
    # create global animation if set
    if app.waypoints.texture.animated
        app.waypoints.texture.animation = window.setInterval ->
            if app.waypoints.material.alpha >= 1 || app.waypoints.material.alpha < .5
                app.waypoints.texture.animationStep *= -1
             app.waypoints.material.alpha += app.waypoints.texture.animationStep
            return
        , app.waypoints.texture.animationTime
    # build individual waypoints
    for waypointData in app.waypoints.data
        # build mesh
        waypoint = BABYLON.MeshBuilder.CreatePlane("waypoint_" + waypointData.name, {size: 2}, app.scene)
        # assign global material
        waypoint.material = app.waypoints.material
        # set position
        waypoint.position = waypointData.position
        waypoint.rotation = new BABYLON.Vector3 Math.PI / 2, 0, 0
        # bind click action
        waypoint.actionManager = new BABYLON.ActionManager app.scene
        waypoint.actionManager.registerAction(
            new BABYLON.InterpolateValueAction(
                BABYLON.ActionManager.OnPickUpTrigger,
                app.camera, 
                "position",
                new BABYLON.Vector3(waypoint.position.x, waypoint.position.y + app.waypoints.cameraLift, waypoint.position.z),
                app.waypoints.transitionTime,
                null,
                false
            )
        )
        waypoint.actionManager.registerAction(
            new BABYLON.InterpolateValueAction(
                BABYLON.ActionManager.OnPickUpTrigger,
                app.camera,
                "radius",
                app.cameraRadius.near,
                app.waypoints.transitionTime,
                null,
                false
            )
        )
        waypoint.actionManager.registerAction(
            new BABYLON.SetValueAction(
                BABYLON.ActionManager.OnPickUpTrigger,
                app.camera,
                "target",
                waypointData.target,
            )
        )

        waypoint.actionManager.registerAction new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickUpTrigger, (e) ->
            window.clearInterval app.waypoints.triggerDelay
            app.waypoints.triggerDelay = window.setInterval ->
                window.clearInterval app.waypoints.triggerDelay
                app.cameraTargetNulled = false
                return
            , app.waypoints.transitionTime
            return
        ,null)
        # bind hover actions
        waypoint.actionManager.registerAction new BABYLON.InterpolateValueAction(BABYLON.ActionManager.OnPointerOutTrigger, waypoint, "scaling", new BABYLON.Vector3(1, 1, 1), 150)
        waypoint.actionManager.registerAction new BABYLON.InterpolateValueAction(BABYLON.ActionManager.OnPointerOverTrigger, waypoint, "scaling", new BABYLON.Vector3(1.1, 1.1, 1.1), 150)
        # add to global waypoints elements list
        app.waypoints.elements["waypoint_" + waypointData.name] = waypoint
    return

# #### SHADOWS ####
app.CreateShadowGenerator = (light, size, min, max, enableBlur, enableContactHard) ->
    shadowGen = new BABYLON.ShadowGenerator size, light
    if enableBlur
        shadowGen.useBlurCloseExponentialShadowMap = true
    if enableContactHard
        shadowGen.useContactHardeningShadow = true
    if min != undefined && max != undefined
        light.shadowMinZ = min
        light.shadowMaxZ = max
    app.shadowGens[light.name] = shadowGen
    return app.shadowGens[light.name]

# #### SCENE RUNNER ####
app.RunScene = ->
    app.engine.runRenderLoop ->
        app.scene.render()
        if app.sceneOptimized
            app.InfopointsZoomShifter()
        return
    return

# #### WINDOW RESIZE ####
app.WindowResizeChecker = ->
    window.addEventListener 'resize', (e) ->
        window.clearInterval app.windowResizeInterval
        app.windowResizeInterval = window.setInterval ->
            app.engine.resize()
            return
        , app.windowResizeDelay
        return
    return

app.Main = ->
    app.CreateScene()
    app.RunScene()
    app.WindowResizeChecker()
    app.UserHintsAnimation()
    return

window.addEventListener 'DOMContentLoaded', ->
    app.Main()
    return